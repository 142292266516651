import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import {
  AuthUser,
  getCurrentUser,
  signInWithRedirect,
  signOut,
} from "aws-amplify/auth";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ADMIN_COGNITO_PROVIDER_NAME } from "@s2z-platform/constants";
import { useAppDispatch } from "../../store/hooks";
import { enqueueErrorMessage } from "../../store/slices/appSlice";
import { logout as authLogout } from "../../store/slices/authSlice";

export type UseOAuthArgs = {
  redirectSignIn: string;
  redirectSignOut: string;
};

let origin = "";
if (typeof window !== "undefined") {
  origin = window.location.origin;
}

export const buildDefaultConfig = ({
  redirectSignIn = origin,
  redirectSignOut = origin,
}: {
  redirectSignIn?: string;
  redirectSignOut?: string;
}) => ({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_ADMIN_USER_POOL_ID as string,
      identityPoolRegion: process.env
        .REACT_APP_COGNITO_ADMIN_IDENTITY_POOL_REGION as string,
      userPoolClientId: process.env
        .REACT_APP_COGNITO_ADMIN_USER_POOL_WEB_CLIENT_ID as string,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_ADMIN_OAUTH_DOMAIN,
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn,
        redirectSignOut,
        responseType: "token",
      },
    },
  },
});

const useOAuth = ({ redirectSignIn, redirectSignOut }: UseOAuthArgs) => {
  const intl = useIntl();
  const [user, setUser] = useState<AuthUser | null>(null);
  const defaultConfig = useMemo(
    () => buildDefaultConfig({ redirectSignIn, redirectSignOut }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const dispatch = useAppDispatch();

  const handleSignInFailure = ({ data }: any) => {
    let msg;
    // check if the login failed du to trigger validation
    if (
      /^(PreAuthentication|PreSignUp)/.test(data.message) &&
      /status\+code\+400/.test(data.message)
    ) {
      msg = intl.formatMessage({ id: "login.error.inexistant_account" });
    } else {
      msg = intl.formatMessage({ id: "login.error.login_issue" });
    }
    dispatch(enqueueErrorMessage(msg));
  };

  useEffect(() => {
    Amplify.configure(defaultConfig);

    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          if (!isEqual(user, payload.data)) {
            setUser(payload.data);
          }
          break;
        case "signedOut":
        case "tokenRefresh_failure":
          setUser(null);
          dispatch(authLogout());
          break;
        case "customOAuthState":
          // eslint-disable-next-line no-console
          console.info("Custom auth state:", payload.data);
          break;
        case "signInWithRedirect_failure":
          // eslint-disable-next-line no-console
          console.info("Sign in failure", {
            event: payload.event,
            data: payload.data,
          });
          handleSignInFailure({ event: payload.event, data: payload.data });
          break;
        default:
      }
    });

    getCurrentUser()
      .then((currentUser) => {
        if (!isEqual(currentUser, user)) {
          setUser(currentUser);
        }
      })
      .catch(() => {});

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectSignIn, redirectSignOut]);

  const logout = async () => {
    const config = defaultConfig;
    config.Auth.Cognito.oauth.redirectSignOut = redirectSignOut;

    Amplify.configure(config);
    const res = await signOut();

    return res;
  };

  const login = async () => {
    const config = defaultConfig;
    config.Auth.Cognito.oauth.redirectSignIn = redirectSignIn;
    Amplify.configure(config);
    const res = await signInWithRedirect({
      provider: { custom: ADMIN_COGNITO_PROVIDER_NAME },
    });

    return res;
  };

  return {
    user,
    login,
    logout,
  };
};

export default useOAuth;
